@import "colors.top5b.css";






.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.App {
  text-align: left;
  min-height: 10vh;
  color: green;
  

}

.Body {
  
  margin: 0;
  background-color:  #0F4C5C;
  max-width: 100vw ;
  color: red;
}



.navBarTitle {
  font-size: 4vw;  
}

.navBarBox {


top :0;
text-align: center;
font-weight: bold;

}



.optBtn {


  font-size: calc(5px + 1vw);
  min-width: calc(15px + 9vw);
  border-radius: 30px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  
  }

  .optBtn:hover {
opacity: .7;
    }
  

.navBarLink3 {


font-size: calc(5px + 1vw);
min-width: calc(15px + 9vw);
border-radius: 30px;
border-color: black;
border-style: solid;
border-width: 1px;

}

.navBarLink3:hover {
  color: white;
  background-color: black;
  }
  

.navBarLink3blue {
font-size: calc(5px + 1vw);
min-width: calc(15px + 9vw);

border-radius: 30px;
border-color: black;
border-style: solid;
border-width: 1px;
}

.navBarLink3blue:hover {
  color: black;
  background-color: white;
  }
  





.Mid-bar {
font-Size:2.2vw;

min-width:48.25vw;
max-width:48.25vw
}



.navBarTitleAuth {
  
  font-size: 2.7vw;
  font-weight: bold;
 
 
}

.pageContent {
  text-align: center;
  
}

.pageContentA {
  text-align: center;
}



.helpContent {
  text-align: center;
}


.pageContentBold {
  text-align: center;
  font-weight: bold;
}

.pageContentLeft {
  text-align: left;
  font-size: 16px;
}

 

.pageContentSmall {
  text-align: center;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%; 
}

.pageContentSmallLeft {
  text-align: left;
  font-size: 14px;
  color: red;
  
}



.pageContentMed {
  text-align: center;
  font-size: 23px;
  color: red;
}

.pageContentMedLeft {
  text-align: left;
  font-size: 23px;
}

.pageContentMedBold {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
}




.pageContentMed2 {
  text-align: center;
  
  font-Size:calc(5px + 3.4vh);
}




.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vh);

}

.App-logo {
  width: 4.7vw;
}


