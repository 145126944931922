@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  
  --amplify-components-authenticator-form-padding:2vw;
  
  --amplify-font-sizes-medium: 2vh;
  
  

  --amplify-colors-brand-primary-80: #FECD45;
 
  --amplify-components-authenticator-max-width: 100vw;
  --amplify-components-authenticator-container-width-max: 32vw;

  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: #FECD45;
  --amplify-components-tabs-item-border-color: #FECD45;

  --amplify-components-authenticator-modal-background-color: #000000;
  --amplify-components-authenticator-router-background-color: #000000;

  --amplify-components-authenticator-state-inactive-background-color: rgb(56, 56, 56);

  

  --amplify-components-authenticator-router-border-color: #FECD45;

  --amplify-components-button-border-radius: 4vh;

  --amplify-components-fieldcontrol-border-color: #FECD45;

  --amplify-components-field-label-color: #FECD45;
  --amplify-components-fieldcontrol-focus-border-color:#FECD45;

  --amplify-colors-font-primary: #FECD45;
  --amplify-colors-font-secondary:rgb(184, 184, 184);
  --amplify-colors-font-tertiary:#FECD45;
  --amplify-colors-font-focus: #FECD45;
  --amplify-colors-font-hover: rgb(255, 255, 255);
  --amplify-components-button-primary-color: rgb(0, 0, 0);
  --amplify-components-button-primary-hover-background-color: rgb(255, 255, 255);
  --amplify-components-button-primary-hover-color: black ;
  --amplify-components-link-hover-color: rgb(255, 255, 255);
  --amplify-components-passwordfield-button-hover-background-color: rgb(0, 0, 0);
  --amplify-components-passwordfield-button-focus-color: #FECD45 ;
  --amplify-components-passwordfield-button-focus-border-color:#FECD45 ;
  --amplify-components-passwordfield-button-hover-border-color: #FECD45;
  --amplify-components-passwordfield-button-active-border-color: #FECD45;
  
  

  --amplify-radii-small: 4vh;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}


