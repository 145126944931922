.App {
  background-color: #000000;
}


.Toastify__toast--warning {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #9A031E;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__toast--success {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #1e839c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__toast--error {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #9A031E;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__toast--info {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #1e839c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__progress-bar--info {
  background: #1e839c  !important;
}
.Toastify__progress-bar--success {
  background: #1e839c !important;
}
.Toastify__progress-bar--warning {
  background: #FB8B24 !important;
} 
.Toastify__progress-bar--error {
  background: #9A031E !important;
}

.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  color: #ffffff; /* #10171D */
  font-size: 0.875rem !important;
}
.toastProgress {
  background: #FB8B24!important;
}

.BK-button {
  background-color: #000000 ;
  color: white;
}


.GR-button {
  background-color: #4b4b4b ;
  color: rgb(179, 179, 179);
}

.App-title {
  color: #ca0c2f;
}

.App-user {
  color:  #1e839c;
}

.App-bank {
  color: #d4d3d3;
}

.App-SDT {
  color: #0F4C5C;
}

.App-running {
  color: #FB8B24;
}

.App-processing {
  color: #FB8B24;
}

.App-stopped {
  color: #ffffff;
}

.Misc-button {
  background-color: #FECD45;
  color: rgb(0, 0, 0) ;
}

.Misc-button:hover {
  opacity: .75;
}


.AT-button {
  background-color: #0F4C5C;
  color: white;
}

.SO-button {
  background-color:  #9A031E;
  color: white;
}

.RDP-button {
  background-color: #FECD45;
  color: rgb(0, 0, 0);
}

.RDP-button:hover {
  opacity: .75;
}

.RB-button {
  background-color: #FB8B24;
  color: white;
}

.RT-button {
  background-color: #FB8B24;
  color: white;
}

.ST-button {
  background-color: #FECD45;
  color: rgb(0, 0, 0);
}

.SP-button {
  background-color: #FECD45;
  color: rgb(0, 0, 0);
}

.SP-button:hover {
  opacity: .75;
}

.ST-button:hover {
  opacity: .75;
}

.SP-button:hover {
  opacity: .75;
}

.PR-button {
  background-color: #FECD45;
  color: rgb(0, 0, 0);
}



.Mid-bar {
  background-color: #FECD45;
  color: #000000;
  z-index: 10;
}

.Top-title {
  color: rgb(0, 0, 0) ;
  z-index: 10;
}


.footBox {  
  color: rgb(255, 255, 255);
  
  
 }

 .divBox {
  background-color: black;   
}

.emailLink {
  color: rgb(255, 255, 255);
}

.navBarLink {
  color: #000000;
  
  }

.navBarLink2 {
  color: #ffffff;
  background-color: #000000 ; 
}

.optBtn {
  color: black;
  background-color: #FECD45; 
}


.navBarLink3 {
  color: black;
  background-color: #FECD45; 
}

.navBarLink3blue {
  color: rgb(255, 255, 255);
  background-color: #2568FB; 
}

.navBarLink3red {
  color: rgb(255, 255, 255);
  background-color: #9A031E; 
}


.navBarLink4 {
  color: #2568FB;
  background-color: #FECD45; 
}

.navBarLinkActive {
  
  color: #2568FB ; 
  
}

.navBarTitle {
  color:#000000;
  
}

.navBarTitleAuth {
  color:#000000;
  
}

 .navBarBox {
    background-color:  #FECD45;
  }

.App-link-button {
  background-color: #2568FB;
  color: #ffffff;
}

.pageContent {
  color: #000000;
  background-color: #FECD45;
}

.pageContent2 {
 color:#FECD45
 
}

.pageContentBold {
  color: #000000;
  background-color: #FECD45;
}

.pageContentA {
  color: #ffffff;
  
}

.helpContent {
  color: #e0e0e0;

}


.imageBorder {
  color: white;
}

.boxBorder {
box-shadow: inset 0 0 0 1px grey;
}

.boxBorder2 {
  border-color: #FECD45;
  border-style:ridge;
  border-left-width: 3vw ;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-radius: 8px;
  margin-top: 5.5vh;
  mix-blend-mode: exclusion;
  z-index: 15;
  margin-left: 5vw;
  margin-right: 0vw;
  }

  .boxBorder3 {
    border-color: #2568FB;
    border-style: solid;
    border-left-width: Calc(10px + 5vh) ;
    border-right-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-radius: 8px;
    
    
    }

.pageContentMed2 {
  color: rgb(0, 0, 0);
}

.pageContentHedder {
  color: #ffffff    ;
}

.pageContentHedderLower {
  color: #ffffff ;
  
}

.pageContentHedder2 {
  color: #000000 ;
  border-color: #2568FB;
  visibility:hidden;
  border-style: solid;
  border-width: 0px;
  border-radius: 8px;
  background-color: #FECD45 ;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-top: 0vh;
  margin-left: 0vw;
  margin-right: 0vw;
  min-width: 46vw;
}


.tosContentSmall2 {
  color: #e0e0e0;;
}

.tosContentMedBold {
  color: #1f87a1;
}

.tosContentBold {
  color: #1f87a1;
}

.tosContentTitle {
  color: #ca0c2f;
}



.helpContentHedder {
  color: #1f87a1;
}

.helpContentTitle {
  color: #ca0c2f;
}



